import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { fetchDocument } from 'api/assessments'
import { fetchSubjectById } from 'api/subjects'
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom'
import PhysicianHeader from './PhysicianHeader'
import { loadingBar } from '@aws-amplify/ui'

class AssessmentView extends Component {
  constructor(props) {
    super()
    this.state = {
      assessment: null,
      isLoading: false,
      subject: {}
    }
  }

  componentWillMount() {
    this.setState({ isLoading: true })
    const { match } = this.props
    const { assessmentId, document } = match.params
    const pid = document.split('.')[0]
    fetchDocument({ id: assessmentId, document }).then(response => {
      const assessment = response.data
      this.setState({ assessment })
      fetchSubjectById({ pid }).then(response => {
        const subject = response.data
        this.setState({ subject, isLoading: false })
      })
    })
  }

  renderAssessmentResults = ({ assessment }) => {
    const { fillingForm } = assessment
    const questions = assessment.sections[0].objects
    const { sections } = fillingForm
    const mainFillingFormSection = sections[0]
    const results = mainFillingFormSection.questions
    return questions.map(question => {
      const questionUid = question.dictUID
      const questionAnswers = results.find(
        result => result.dictUID === questionUid
      ).answers
      const mainElement = question.elements[0]
      const title = mainElement.resource.label
      return (
        <div key={questionUid}>
          <p className='physician_assessment-question-title'>{title}</p>
          <p className='physician_assessment-question-answer'>
            {questionAnswers[0]}
          </p>
        </div>
      )
    })
  }

  render() {
    const { assessment, isLoading, subject } = this.state
    const { pid } = subject
    return (
      <div className='physician_assessment-wrapper'>
        <PhysicianHeader />
        {isLoading ? (
          <div className='assessment-loader_container'>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <p>Fetching assessment data...</p>
          </div>
        ) : (
          <div className='physician_assessment-content'>
            <button
              onClick={() => this.props.history.goBack()}
              className='physician_assessment-button'
            >
              Back To Patient's assessments
            </button>
            <p className='physician_assessment-name'>
              {pid.name.first} {pid.name.last}
            </p>
            <p className='physician_assessment-title'>{assessment.label}</p>
            <div className='physician_assessment-view'>
              <div className='physician_assessment-results'>
                {this.renderAssessmentResults({ assessment })}
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

AssessmentView.propTypes = {
  history: PropTypes.object,
  assessments: PropTypes.array,
  startAssessment: PropTypes.func
}

export default withRouter(AssessmentView)
