import React from 'react'

const hdInsightLogo = require('assets/images/MyHDStory_2.png')

const getSourceOrigin = ({ source }) => {
  if (source) {
    const sourceToLowercase = source.toLowerCase()
    if (sourceToLowercase === 'foxinsight') {
      return 'foxinsight'
    } else if (sourceToLowercase === 'hdinsight') {
      return 'hdinsight'
    } else {
      return ''
    }
  }
}

const renderDynamicLogo = ({ sourceOrigin }) => {
  let logo = hdInsightLogo
  return (
    <div
      className={`physician-header_logo-container physician-header_logo-container--${sourceOrigin ||
        'nt'}`}
    >
      <img className='physician-header_logo' src={logo} />
    </div>
  )
}

const Header = ({ source }) => {
  const sourceOrigin = getSourceOrigin({ source })
  return (
    <div className='physician-header-container'>
      <div className='physician-header_content-container'>
        {renderDynamicLogo({ sourceOrigin })}
      </div>
    </div>
  )
}

export default Header
