const Types = {
  SET_USER_TYPE: 'SET_USER_TYPE',
};

export const setUserType = ({ isSubject }) => ({
  type: Types.SET_USER_TYPE,
  payload: isSubject
})

export default {
  setUserType,
  Types
};