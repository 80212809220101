import { createStore, applyMiddleware, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

// Logger with default options
import logger from 'redux-logger';

import subjectReducer from './reducers/subjectReducer'
import userReducer from './reducers/userReducer'

const rootReducer = combineReducers({
  subject: subjectReducer,
  user: userReducer // key name same as the carefully renamed named export
})

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default function configureStore(initialState) {
  const store = createStore(persistedReducer,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
    initialState,
    applyMiddleware(logger));
  const persistor = persistStore(store)
  
  return { store, persistor }
}