import React, { useState } from 'react'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Loader from 'react-loader-spinner'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import { setSubjectProfile, setSubject } from 'reducer/actions/subjectActions'
import { setUserType } from 'reducer/actions/userActions'
import { loginWithAwsCognito } from '../authentication/authentication'
import TermsOfServiceModal from './TermsOfService'

const hdLogo = require('assets/images/MyHDStory_2.png')

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '',
    width: '6rem',
    height: '6rem'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#8DC440',
    '&:hover': {
      background: '#8DC440'
    }
  },
  disabled: {
    margin: theme.spacing(3, 0, 2),
    background: '#D3D3D3',
    '&:hover': {
      background: '#D3D3D3'
    }
  },
  notchedOutline: {
    borderColor: '#8DC440 !important',
    color: '#8DC440 !important'
  }
}))

const handleSubmit = ({
  e,
  username,
  password,
  setLoggingInStatus,
  setLoginError,
  isTosChecked,
  setUserReturnedFromDb,
  setSubjectProfile,
  isPatientLogin,
  setUserType
}) => {
  e.preventDefault()
  if (isTosChecked) {
    setLoggingInStatus(true)
    loginWithAwsCognito({
      username,
      password,
      setLoggingInStatus,
      setLoginError,
      setUserReturnedFromDb,
      setSubjectProfile,
      isSubject: isPatientLogin,
      setUserType
    })
  }
}

const SignIn = props => {
  const isPatientLogin = !props.location.pathname.includes('physician')
  const { setSubjectProfile, setUserType } = props
  const classes = useStyles()
  const [username, handleUsernameChange] = useState('')
  const [password, handlePasswordChange] = useState('')
  const [isLoggingIn, setLoggingInStatus] = useState(false)
  const [loginError, setLoginError] = useState(false)
  const [isOpen, openModel] = useState(false)
  const [isTosChecked, checkTos] = useState(false)
  const [userFromDb, setUserReturnedFromDb] = useState(null)

  const userFromCache = JSON.parse(localStorage.getItem('user'))

  const submitButtonClasses = isTosChecked ? classes.submit : classes.disabled
  const handleOpen = () => {
    openModel(true)
  }

  const handleClose = () => {
    openModel(false)
  }

  const handleTosCheck = () => {
    const isChecked = !isTosChecked
    checkTos(isChecked)
  }

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        {!isLoggingIn ? (
          <>
            <img
              alt='neurotargeting-icon'
              className='login_logo'
              src={hdLogo}
            />
            <Typography style={{ color: '#333' }} component='h1' variant='h5'>
              {isPatientLogin ? 'Patient Sign In' : 'Sign In'}
            </Typography>
            {!isPatientLogin && (
              <p className='login_patient-message'>
                If you are a patient, please sign in at our{' '}
                <a href='/patient/login'>patient portal</a>
              </p>
            )}
            <form
              className={classes.form}
              onSubmit={e =>
                handleSubmit({
                  e,
                  username,
                  password,
                  setLoggingInStatus,
                  setLoginError,
                  isTosChecked,
                  setUserReturnedFromDb,
                  setSubjectProfile,
                  isPatientLogin,
                  setUserType
                })
              }
              noValidate
            >
              <TextField
                style={{ borderColor: 'red' }}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email'
                name='email'
                autoComplete='email'
                autoFocus
                onChange={e => handleUsernameChange(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  className: 'login_input-label'
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={e => handlePasswordChange(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    floatingLabelFocusStyle: classes.floatingLabelFocusStyle
                  }
                }}
                InputLabelProps={{
                  className: 'login_input-label'
                }}
              />
              <div className='login_tos-container'>
                <input
                  onClick={() => handleTosCheck()}
                  checked={isTosChecked}
                  className='login_tos-checkbox'
                  type='checkbox'
                />
                <p className='login_tos-text'>
                  I agree to Neurotargeting's
                  <span onClick={handleOpen} className='login_tos-text-link'>
                    {' '}
                    Terms of Service
                  </span>
                </p>
                <TermsOfServiceModal
                  isOpen={isOpen}
                  handleClose={handleClose}
                />
              </div>
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={submitButtonClasses}
              >
                Sign In
              </Button>
              {isPatientLogin && (
                <p className='login_sign-up-message'>
                  Don't have an account, <a href='/signup'>Sign Up</a>
                </p>
              )}
              {loginError && (
                <Typography
                  style={{ color: 'red', textAlign: 'center' }}
                  component='p'
                  variant='p'
                >
                  {loginError}
                </Typography>
              )}
            </form>
          </>
        ) : (
          <>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <Typography
              style={{ textAlign: 'center', color: '#333' }}
              component='p'
              variant='p'
            >
              Signing in...
            </Typography>
          </>
        )}
      </div>
    </Container>
  )
}

const mapDispatchToProps = {
  setSubjectProfile,
  setUserType
}

export default connect(
  null,
  mapDispatchToProps
)(SignIn)
