import React, { Component } from 'react'
import { connect } from 'react-redux'
import { addSubject } from 'api/subjects'
import { updateProfileSubjectId, updateProfile } from 'api/profile'
import PhysicianHeader from '../Physician/PhysicianHeader'
import Loader from 'react-loader-spinner'
import { setSubject, setSubjectProfile } from 'reducer/actions/subjectActions'
import { createAssessment } from 'api/assessments'
import { getSubjectProfile } from 'api/profile'
import { HSG_HEALTH_INDEX_ASSESSMENT_ID } from '../../constants'

const PATIENT_USER_POOL_ID = process.env.REACT_APP_PATIENT_APP_CLIENT

class Profile extends Component {
  constructor(props) {
    super()
    this.state = {
      firstname: '',
      lastname: '',
      dob: '',
      prefix: 'M',
      phone: '',
      mrn: 'null',
      doesUserHaveProfile: true,
      userProfile: null,
      inputsAreNotValid: false,
      email: props.profile.email,
      guid: PATIENT_USER_POOL_ID
    }
    this.dobRegex = /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/
  }

  addAssessmentToPatient = ({ subjectId, doesUserHaveProfile }) => {
    return (
      !doesUserHaveProfile &&
      createAssessment({
        assessment: {
          id: HSG_HEALTH_INDEX_ASSESSMENT_ID
        },
        pid: subjectId
      })
    )
  }
  componentWillMount() {
    const { subjectId, email } = this.props.profile
    const doesUserHaveSubjectId = subjectId && subjectId !== 'null'
    if (!doesUserHaveSubjectId) {
      this.setState({ doesUserHaveProfile: false })
    } else {
      getSubjectProfile({
        username: email,
        group: PATIENT_USER_POOL_ID
      }).then(response => {
        const userProfile = response.data
        this.setState({ userProfile })
        const {
          firstname,
          lastname,
          email,
          guid,
          dob,
          prefix,
          phone,
          about
        } = userProfile
        this.setState({
          firstname,
          lastname,
          email,
          guid,
          dob,
          prefix,
          phone,
          about
        })
      })
    }
  }

  checkForValidDob = ({ dob }) => {
    const validateDob = this.dobRegex.exec(dob)
    if (validateDob && validateDob[0] === validateDob.input) {
      return true
    } else {
      return false
    }
  }

  checkForValidInputs = () => {
    const { firstname, lastname, dob, prefix } = this.state
    const isDobValid = this.checkForValidDob({ dob })
    if (firstname && lastname && dob && prefix && isDobValid) {
      return true
    } else {
      return false
    }
  }

  handleInputChange = ({ input, value }) => {
    if (this.state.inputsAreNotValid) {
      this.setState({ inputsAreNotValid: false })
    }
    this.setState({ [input]: value })
  }

  handleSubmit = async () => {
    this.setState({
      inputsAreNotValid: false
    })
    const { subjectId } = this.props.profile
    const doesUserHaveProfile = subjectId && subjectId !== 'null'
    const {
      firstname,
      lastname,
      email,
      guid,
      dob,
      prefix,
      phone,
      about
    } = this.state
    const { history, setSubject, profile, setSubjectProfile } = this.props
    const userGroup = profile.group
    const subjectPayload = {
      dg: {
        prefix: prefix || 'null'
      },
      pid: {
        dob,
        isid: {
          guid,
          mrn: 'null'
        },
        name: {
          first: firstname,
          last: lastname
        }
      },
      email
    }
    const areAllInputsValid = this.checkForValidInputs()
    if (areAllInputsValid) {
      this.setState({ isSavingProfile: true })
      if (!doesUserHaveProfile) {
        await addSubject({ subject: subjectPayload, group: userGroup }).then(
          async response => {
            const { userId, group } = profile
            const subject = response.data
            const subjectId = subject.id
            // adding HD-HI assessment by default if user signs up
            await this.addAssessmentToPatient({
              subjectId,
              doesUserHaveProfile
            })
            await updateProfileSubjectId({ subjectId, group, userId }).then(
              async response => {
                const updatedProfile = response.data
                const profileWithInputData = {
                  ...updatedProfile,
                  isSubject: true,
                  firstname: firstname || null,
                  lastname: lastname || null,
                  email: email || null,
                  guid: guid || null,
                  dob: dob || null,
                  prefix: prefix || null,
                  phone: phone || null,
                  about: about || null
                }
                await updateProfile({
                  updatedProfile: profileWithInputData,
                  userId
                }).then(async response => {
                  await setSubjectProfile({
                    profile: {
                      ...profileWithInputData
                    }
                  })
                  setSubject({ subject })
                  history.push('/')
                  this.setState({ isSavingProfile: false })
                })
              }
            )
          }
        )
      } else {
        const { userProfile } = this.state
        const profileWithInputData = {
          ...userProfile,
          isSubject: true,
          firstname: firstname || null,
          lastname: lastname || null,
          email: email || null,
          guid: guid || null,
          dob: dob || null,
          prefix: prefix || null,
          phone: phone || null,
          about: about || null
        }
        await updateProfile({
          updatedProfile: profileWithInputData,
          userId: userProfile.userId
        }).then(async response => {
          await setSubjectProfile({
            profile: {
              ...profileWithInputData
            }
          })
          // setSubject({ subject })
          this.setState({ isSavingProfile: false })
        })
      }
    } else {
      this.setState({
        inputsAreNotValid: true
      })
    }
  }

  render() {
    const {
      doesUserHaveProfile,
      inputsAreNotValid,
      isSavingProfile
    } = this.state
    return (
      <div className='profile_container'>
        <PhysicianHeader />
        {isSavingProfile ? (
          <div className='assessment-loader_container'>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <p>Saving Your Profile...</p>
          </div>
        ) : (
          <>
            <div className='profile_content-wrapper'>
              <i onClick={() => this.props.history.push('/')} className='profile_home-button fas fa-home' />
              <h1 className='profile_title'>My Profile</h1>
              {!doesUserHaveProfile && (
                <p style={{ textAlign: 'center' }}>
                  Before we get started, we need you to set up your profile.
                </p>
              )}
              <div className='profile_content'>
                <div className='profile_content-meta'>
                  <i className='profile_default-icon fas fa-user-circle' />
                  <p className='profile_about-label'>About</p>
                  <textarea
                    rows={7}
                    cols={40}
                    className='profile_content-meta-about'
                    placeholder='Write a short description about who you are...'
                    value={this.state.about}
                    onChange={e =>
                      this.handleInputChange({
                        input: 'about',
                        value: e.target.value
                      })
                    }
                  />
                  <button
                    className='profile_submit-button'
                    onClick={() => this.handleSubmit()}
                  >
                    Save
                  </button>
                </div>

                <div className='profile_inputs-container'>
                  <div className='profile_input-wrapper'>
                    <p>
                      <span className='profile_required-asterisk'>*</span>
                      First Name
                    </p>
                    <input
                      className='profile_input-text'
                      onChange={e =>
                        this.handleInputChange({
                          input: 'firstname',
                          value: e.target.value
                        })
                      }
                      value={this.state.firstname}
                      type='text'
                    />
                  </div>
                  <div className='profile_input-wrapper'>
                    <p>
                      <span className='profile_required-asterisk'>*</span>
                      Last Name
                    </p>
                    <input
                      className='profile_input-text'
                      onChange={e =>
                        this.handleInputChange({
                          input: 'lastname',
                          value: e.target.value
                        })
                      }
                      value={this.state.lastname}
                      type='text'
                    />
                  </div>
                  <div className='profile_input-wrapper'>
                    <p>
                      <span className='profile_required-asterisk'>*</span>
                      DOB
                    </p>
                    <input
                      className='profile_input-text'
                      onChange={e =>
                        this.handleInputChange({
                          input: 'dob',
                          value: e.target.value
                        })
                      }
                      placeholder='YYYY-MM-DD'
                      value={this.state.dob}
                      type='text'
                    />
                  </div>
                  <div className='profile_phone-prefix-container'>
                    <div className='profile_input-wrapper'>
                      <p>Phone</p>
                      <input
                        className='profile_input-text profile_input-text--phone'
                        onChange={e =>
                          this.handleInputChange({
                            input: 'phone',
                            value: e.target.value
                          })
                        }
                        value={this.state.phone}
                        type='tel'
                      />
                    </div>
                    <div className='profile_input-wrapper'>
                      <p>Prefix</p>
                      <select
                        className='profile_input-select'
                        onChange={e =>
                          this.handleInputChange({
                            input: 'prefix',
                            value: e.target.value
                          })
                        }
                        value={this.state.prefix}
                        type='text'
                      >
                        <option value='M'>Mr.</option>
                        <option value='F'>Mrs.</option>
                        <option value='other'>other</option>
                      </select>
                    </div>
                  </div>
                  <div className='profile_input-wrapper'>
                    <p>Email</p>
                    <input
                      className='profile_input-text'
                      onChange={e =>
                        this.handleInputChange({
                          input: 'email',
                          value: e.target.value
                        })
                      }
                      disabled
                      value={this.state.email}
                      type='text'
                    />
                  </div>
                </div>
              </div>
              {inputsAreNotValid && (
                <p
                  style={{
                    color: 'red',
                    fontSize: '12px',
                    textAlign: 'center'
                  }}
                >
                  First Name, Last Name, and DOB are required. Please make sure
                  to use YYYY-MM-DD for the DOB format.
                </p>
              )}
            </div>
          </>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.subject.profile
})

const mapDispatchToProps = {
  setSubject,
  setSubjectProfile
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
