import Amplify, { Auth } from 'aws-amplify'
import { createSubjectProfile, getSubjectProfile } from 'api/profile'
import hash from 'object-hash'
import jwtDecode from 'jwt-decode'
// import { createUser, getUser } from 'api/users'
// import { fetchDataForUser, createUserView } from 'api/userView'

export const checkForAuthenticatedUser = async ({ isSubject }) => {
  console.log('IS SUBJECT IN CHECK', isSubject)
  const userPoolId = isSubject
    ? process.env.REACT_APP_PATIENT_USER_POOL
    : process.env.REACT_APP_HSG_USER_POOL
  const userPoolWebClientId = isSubject
    ? process.env.REACT_APP_PATIENT_APP_CLIENT
    : process.env.REACT_APP_HSG_APP_CLIENT
  console.log('userPOOL Id', userPoolId)
  console.log('userPoolWebClientId', userPoolWebClientId)
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false
    }
  })
  return await new Promise((resolve, reject) => {
    Auth.currentSession()
      .then(data => {
        resolve(data)
      })
      .catch(err => reject(err))
  })
}

export const logout = () => {
  console.log('WRWERWERWER')
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId: process.env.REACT_APP_PATIENT_USER_POOL,
      userPoolWebClientId: process.env.REACT_APP_PATIENT_APP_CLIENT,
      mandatorySignIn: false
    }
  })
  Auth.signOut()
  window.location = '/login'
}

export const loginWithAwsCognito = async ({
  username,
  password,
  setLoggingInStatus,
  setLoginError,
  setSubjectProfile,
  isSubject,
  setUserType
}) => {
  const userPoolId = isSubject
    ? process.env.REACT_APP_PATIENT_USER_POOL
    : process.env.REACT_APP_HSG_USER_POOL
  const userPoolWebClientId = isSubject
    ? process.env.REACT_APP_PATIENT_APP_CLIENT
    : process.env.REACT_APP_HSG_APP_CLIENT
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false
    }
  })
  try {
    const user = await Auth.signIn(username, password)
    if (
      user.challengeName === 'SMS_MFA' ||
      user.challengeName === 'SOFTWARE_TOKEN_MFA'
    ) {
      // You need to get the code from the UI inputs
      // and then trigger the following function with a button click
      // const code = getCodeFromUserInput()
      // If MFA is enabled, sign-in should be confirmed with the confirmation code
      const loggedUser = await Auth.confirmSignIn(
        user // Return object from Auth.signIn()
        // code,   // Confirmation code
        // mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
      )
    } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
      // const { requiredAttributes } = user.challengeParam // the array of required attributes, e.g ['email', 'phone_number']
      // // You need to get the new password and required attributes from the UI inputs
      // // and then trigger the following function with a button click
      // // For example, the email and phone_number are required attributes
      // const { username, email, phone_number } = getInfoFromUserInput()
      console.log('NEW PASS REQuIRED')
    } else if (user.challengeName === 'MFA_SETUP') {
      // This happens when the MFA method is TOTP
      // The user needs to setup the TOTP before using it
      // More info please check the Enabling MFA part
      Auth.setupTOTP(user)
    } else {
      // The user directly signs in
      const accessTokenFromCognito = user.signInUserSession.accessToken.jwtToken
      if (user) {
        const { username } = user
        getSubjectProfile({ username, group: user.pool.clientId }).then(
          async response => {
            if (isSubject) {
              await setUserType({ isSubject: true })
              const userProfile = response.data
              await setSubjectProfile({
                profile: {
                  ...userProfile,
                  isSubject
                }
              })
              window.location = '/profile'
            } else {
              await setUserType({ isSubject: false })
              localStorage.setItem('jwtToken', accessTokenFromCognito)
              window.location = '/physician'
            }
          }
        )
      }
    }
  } catch (err) {
    setLoggingInStatus(false)
    setLoginError(err.message)
    if (err.code === 'UserNotConfirmedException') {
      // The error happens if the user didn't finish the confirmation step when signing up
      // In this case you need to resend the code and confirm the user
      // About how to resend the code and confirm the user, please check the signUp part
    } else if (err.code === 'PasswordResetRequiredException') {
      // The error happens when the password is reset in the Cognito console
      // In this case you need to call forgotPassword to reset the password
      // Please check the Forgot Password part.
    } else if (err.code === 'NotAuthorizedException') {
      // The error happens when the incorrect password is provided
    } else if (err.code === 'UserNotFoundException') {
      // The error happens when the supplied username/email does not exist in the Cognito user pool
    } else {
      console.log(err)
    }
  }
}

export const signupWithCognito = ({
  password,
  email,
  isSubject,
  setSignupError,
  setSigningUpStatus
}) => {
  const userPoolId = isSubject
    ? process.env.REACT_APP_PATIENT_USER_POOL
    : process.env.REACT_APP_HSG_USER_POOL
  const userPoolWebClientId = isSubject
    ? process.env.REACT_APP_PATIENT_APP_CLIENT
    : process.env.REACT_APP_HSG_APP_CLIENT
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false
    }
  })
  Auth.signUp({
    username: email,
    password,
    attributes: {
      email
    },
    validationData: []
  })
    .then(data => {
      createSubjectProfile({
        username: email,
        email,
        appClient: data.user.pool.clientId
      }).then(response => {
        //  maybe do something with this...not sure
        const newlyCreatedProfile = response.data
        window.location = `signup/confirmation/${data.user.username}`
      })
    })
    .catch(err => {
      setSigningUpStatus(false)
      setSignupError(err.message)
    })
}

export const confirmSignup = ({
  username,
  code,
  isSubject,
  setSignupError,
  setSigningUpStatus
}) => {
  const userPoolId = isSubject
    ? process.env.REACT_APP_PATIENT_USER_POOL
    : process.env.REACT_APP_HSG_USER_POOL
  const userPoolWebClientId = isSubject
    ? process.env.REACT_APP_PATIENT_APP_CLIENT
    : process.env.REACT_APP_HSG_APP_CLIENT
  Amplify.configure({
    Auth: {
      region: 'us-east-1',
      identityPoolRegion: 'us-east-1',
      userPoolId,
      userPoolWebClientId,
      mandatorySignIn: false
    }
  })
  Auth.confirmSignUp(username, code)
    .then(data => {
      window.location = '/login'
    })
    .catch(err => {
      setSigningUpStatus(false)
      setSignupError(err.message)
    })

  // Auth.resendSignUp(username)
  //   .then(() => {
  //     console.log('code resent successfully');
  //   })
  //   .catch(e => {
  //     console.log(e);
  //   });
}
