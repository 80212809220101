import ACTIONS from '../actions/userActions';
import _ from 'lodash';

const defaultState = {}

const adminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_USER_TYPE: {
      const isSubject = action.payload
      const newState = _.cloneDeep(state);
      newState.isSubject = isSubject
      return newState;
    }
    default:
      return state;
  }
};

export default adminReducer;