import { updateAssessment } from 'api/assessments'
import _ from 'lodash'

// NOTE: this will only work with the HSG assessments as all objects
// only have one section and element per each section
export const getQuestionsFromAssessments = ({ assessments }) => {
  return assessments.map(assessment => {
    const { instructions, id, document, scores, s } = assessment
    const fillingForm = assessment.fillingForm
    const fillingFormSection = assessment.fillingForm.sections[0]
    const areAllQuestionsAnswered = fillingFormSection.questions.every(
      question => question.answers.length > 0
    )
    const assessmentTitle = assessment.label
    const createdAt = assessment.createdAt
    const sections = assessment.sections
    const mainSection = sections[0]
    const objects = mainSection.objects
    const formattedQuestions = objects.reduce((acc, object) => {
      let optionsForScale
      if (object.answer_type === 'scale') {
        const scaleOptionsElement = object.elements[1]
        const scaleOptions = scaleOptionsElement.resource.options
        optionsForScale = scaleOptions
      }
      const dictUid = object.dictUID
      const answerType = object.answer_type
      const mainElement = object.elements[0]
      const questionTitle = mainElement.resource.label
      acc.push({
        questionTitle,
        dictUid,
        answerType,
        optionsForScale
      })
      return acc
    }, [])
    return {
      assessmentTitle,
      instructions,
      id,
      s,
      document,
      scores,
      createdAt,
      questions: formattedQuestions,
      isComplete: areAllQuestionsAnswered,
      fillingForm
    }
  })
}

export const fillForm = ({ assessment, dictUid, answer, isLastQuestion }) => {
  let status
  const updatedAssessment = { ...assessment }
  const fillingFormMainSection = updatedAssessment.fillingForm.sections[0]
  const indexOfCurrentQuestionsForm = fillingFormMainSection.questions.findIndex(
    question => question.dictUID === dictUid
  )
  const currentQuestionForm =
    fillingFormMainSection.questions[indexOfCurrentQuestionsForm]
  currentQuestionForm.answers = [answer]
  if (isLastQuestion) {
    status = 'completed'
  } else {
    status = 'in progrss'
  }
  console.log('UPDATED', updatedAssessment)
  updateAssessment({ assessment: updatedAssessment, s: status })
}
