import React, { Component } from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { checkForSubject } from 'helpers/user'
import { checkForAuthenticatedUser } from '../authentication/authentication'

class PrivateRoute extends Component {
  state = {
    haveAcces: false,
    loaded: false
  }

  componentDidMount() {
    console.log('PROPS', this.props)
    const { isSubject } = this.props
    const jwt = localStorage.getItem('jwtToken')
    const isSignup = this.props.location.pathname.includes('signup')
    const isLogin = this.props.location.pathname.includes('login')
    const isPhysician = this.props.location.pathname.includes('physician')
    console.log('PHYSYY', isPhysician)
    this.setState({
      isSignup,
      isLogin
    })
    if (!isSignup && !isLogin) {
      checkForAuthenticatedUser({ isSubject: (isSubject && !isPhysician) })
        .then(data => {
          this.setState({
            haveAcces: true,
            loaded: true
          })
        })
        .catch(e => {
          if(isPhysician) {
            this.props.history.push('/physician/login')
          } else {
            this.props.history.push('/login')
          }
        })
    }
  }

  render() {
    const { component: Component, ...rest } = this.props
    const { haveAcces, loaded, isSignup, isLogin } = this.state
    const shouldRenderComponent = haveAcces || isSignup || isLogin
    return (
      loaded && (
        <Route
          {...rest}
          render={props => {
            return shouldRenderComponent ? (
              <Component {...props} />
            ) : (
              <Redirect
                to={{
                  pathname: '/login'
                }}
              />
            )
          }}
        />
      )
    )
  }
}

const mapStateToProps = state => ({
  user: state.user,
  subject: state.subject.subject
})

export default withRouter(connect(mapStateToProps)(PrivateRoute))
