import { makeLamdaRequest } from './lambdaHelper'

export const createHSGassessment = ({
  pid,
  schedule = {},
  trace = { by: 'neurotargeting' }
}) => {
  const hsgAssessment = '8514746c3e64b4f07f0b7f946015bae346e1f575'
  return makeLamdaRequest({
    method: 'POST',
    endpoint: 'documents/ass/new',
    body: {
      assessmentId: hsgAssessment,
      pid,
      schedule,
      trace
    }
  })
}

export const fetchSubjectAssessments = ({ pid }) => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `documents/ass/all/${pid}`
  })
}

export const fetchHsgAssessments = () => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: 'documents/ass/group/HSG'
  })
}

export const createAssessment = ({
  assessment,
  pid,
  schedule = {},
  trace = { by: 'HSG' }
}) => {
  return makeLamdaRequest({
    method: 'POST',
    endpoint: 'documents/ass/new',
    body: {
      assessmentId: assessment.id,
      pid,
      schedule,
      trace
    }
  })
}

export const updateAssessment = ({
  assessment,
  s
}) => {
  const { id, document, scores, fillingForm } = assessment
  console.log('SSS', s)
  return makeLamdaRequest({
    method: 'PUT',
    endpoint: 'documents/ass',
    body: {
      id,
      document,
      scores,
      fillingForm,
      s
    }
  })
}

export const fetchDocument = ({ id, document }) => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `documents/doc/${id}/${document}`
  })
}
