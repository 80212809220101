import { makeLamdaRequest } from './lambdaHelper'
import hash from 'object-hash'

export const createSubjectProfile = ({
  username,
  appClient,
  email,
  firstName,
  lastName,
  dob,
  phone
}) => {
  console.log('APP CLIENT', appClient)
  return makeLamdaRequest({
    method: 'POST',
    endpoint: 'profile/create/subject',
    body: {
      username,
      appClient,
      email,
      firstName,
      lastName,
      dob,
      phone
    }
  })
}

export const updateProfileSubjectId = ({ userId, group, subjectId }) => {
  return makeLamdaRequest({
    method: 'PUT',
    endpoint: `profile/update/subjectId/${userId}`,
    body: {
      subjectId,
      group
    }
  })
}

export const updateProfile = ({ updatedProfile, userId }) => {
  return makeLamdaRequest({
    method: 'PUT',
    endpoint: `profile/update/${userId}`,
    body: {
      ...updatedProfile
    }
  })
}

export const getSubjectProfile = ({ username, group }) => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `profile/user/${username}/group/${group}`
  })
}
