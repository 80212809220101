import React, { useState } from 'react'
import Button from '@material-ui/core/Button'
import CssBaseline from '@material-ui/core/CssBaseline'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import Container from '@material-ui/core/Container'
import Loader from 'react-loader-spinner'
import { signupWithCognito } from '../authentication/authentication'

const hdLogo = require('assets/images/MyHDStory_2.png')

const useStyles = makeStyles(theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white
    }
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: '',
    width: '6rem',
    height: '6rem'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    background: '#8DC440',
    '&:hover': {
      background: '#8DC440'
    }
  },
  disabled: {
    margin: theme.spacing(3, 0, 2),
    background: '#D3D3D3',
    '&:hover': {
      background: '#D3D3D3'
    }
  },
  notchedOutline: {
    borderColor: '#8DC440 !important',
    color: '#8DC440 !important'
  }
}))


const handleSubmit = ({
  e,
  username,
  password,
  setSigningUpStatus,
  setSignupError,
  email
}) => {
  e.preventDefault()
    setSigningUpStatus(true)
    signupWithCognito({
      username,
      password,
      setSignupError,
      setSigningUpStatus,
      email,
      isSubject: true
  })
}

const Signup = () => {
  const classes = useStyles()
  const [username, handleUsernameChange] = useState('')
  const [email, handleEmailChange] = useState('')
  const [password, handlePasswordChange] = useState('')
  const [isSigningUp, setSigningUpStatus] = useState(false)
  const [signupError, setSignupError] = useState(false)

  const submitButtonClasses = (email && password)
  ? classes.submit
  : classes.disabled

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        {!isSigningUp
          ? <>
              <img alt='neurotargeting-icon' className='login_logo' src={hdLogo} />
            <Typography style={{ color: '#333' }} component='h1' variant='h5'>
              Patient Sign Up
            </Typography>
            <form
              className={classes.form}
              onSubmit={e => handleSubmit({
                e, username, password, email, setSigningUpStatus, setSignupError
              })}
              noValidate
            >
              <TextField
                style={{ borderColor: 'red' }}
                variant='outlined'
                margin='normal'
                required
                fullWidth
                id='email'
                label='Email Address'
                name='email'
                autoFocus
                onChange={e => handleEmailChange(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline
                  }
                }}
                InputLabelProps={{
                  className: 'login_input-label'
                }}
              />
              <TextField
                variant='outlined'
                margin='normal'
                required
                fullWidth
                name='password'
                label='Password'
                type='password'
                id='password'
                autoComplete='current-password'
                onChange={e => handlePasswordChange(e.target.value)}
                InputProps={{
                  classes: {
                    notchedOutline: classes.notchedOutline,
                    floatingLabelFocusStyle: classes.floatingLabelFocusStyle
                  }
                }}
                InputLabelProps={{
                  className: 'login_input-label'
                }}
              />
              <Button
                type='submit'
                fullWidth
                variant='contained'
                color='primary'
                className={submitButtonClasses}
              >
                Sign Up
              </Button>
              {signupError && <Typography style={{ color: 'red', textAlign: 'center' }} component='p' variant='p'>
                {signupError}
              </Typography>}
              <p className='login_sign-up-message'>Already have an account, <a href='/patient/login'>Sign In</a></p>
            </form>
          </>
          : <>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <Typography style={{ textAlign: 'center', color: '#333' }} component='p' variant='p'>
                Signing you up...
            </Typography>
          </>}
      </div>
    </Container>
  )
}

export default Signup
