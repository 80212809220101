import React, { Component } from 'react'
import { connect } from 'react-redux'
import AssessmentModal from './AssessmentModal'
import moment from 'moment'
import { fetchHsgAssessments, fetchDocument } from 'api/assessments'
import PropTypes from 'prop-types'
import BootstrapTable from 'react-bootstrap-table-next'
import { fetchSubjectById } from 'api/subjects'
import PhysicianHeader from './PhysicianHeader'
import { fetchSubject } from 'api/subjects'
import { fetchSubjectAssessments } from 'api/assessments'
import Loader from 'react-loader-spinner'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'

class SubjectView extends Component {
  constructor(props) {
    super()
    this.state = {
      subject: props.selectedSubject,
      isModalOpen: false,
      assessmentsWithStatus: []
    }
    this.columns = [
      {
        dataField: 'label',
        text: 'Title'
      },
      {
        dataField: 'document',
        text: 'Assigned At',
        formatter: this.dateFormatter
      },
      {
        dataField: 'isCompleted',
        text: 'Completed'
        // formatter: this.completedFormatter
      }
    ]
    this.rowSettings = {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: assessment => {
        window.location =
          '/physician/' + assessment.id + '/' + assessment.document
      }
    }
  }

  componentWillMount() {
    const { subject } = this.state
    const { match } = this.props
    const { subjectId } = match.params
    console.log('SUBJECT', subjectId)
    this.fetchAndSetSubjectsAssessments()
    fetchHsgAssessments().then(response => {
      const hsgAssessments = response.data
      this.setState({ hsgAssessments })
    })
  }

  fetchAndSetSubjectsAssessments = () => {
    const { match } = this.props
    const { subjectId } = match.params
    this.setState({ isLoading: true })
    fetchSubjectById({ pid: subjectId }).then(response => {
      const subject = response.data
      this.setState({ subject })
      fetchSubjectAssessments({ pid: subjectId }).then(response => {
        const patientAssessments = response.data
        const updatedAssessments = this.getAssessmentsWithStatus({
          assessments: patientAssessments
        })
        this.setState({
          assessmentsWithStatus: updatedAssessments,
          isLoading: false
        })
      })
    })
  }

  getAssessmentsWithStatus = ({ assessments }) => {
    return assessments.reduce((acc, ass) => {
      console.log('ASSS', ass)
      const fillingForm = ass.fillingForm.sections[0]
      const areAllQuestionsAnswered = fillingForm.questions.every(
        question => question.answers.length > 0
      )
      ass.isCompleted = areAllQuestionsAnswered
      acc.push(ass)
      return acc
    }, [])
  }

  openAssessmentModal = () => {
    this.setState({ isModalOpen: true })
  }

  dateFormatter = cell => {
    const creationDate = Number(cell.split('.').pop())
    const date = new Date(creationDate)
    const formattedDate = moment(date).format('l')
    return <p style={{ margin: 0 }}>{formattedDate}</p>
  }

  completedFormatter = cell => {
    console.log('CELL', cell)
  }

  closeAssessmentModal = () => {
    const { subject } = this.state
    this.setState({ isModalOpen: false }, () => {
      fetchSubjectAssessments({ pid: subject.id }).then(response => {
        const patientAssessments = response.data
        const updatedAssessments = this.getAssessmentsWithStatus({
          assessments: patientAssessments
        })
        this.setState({ assessmentsWithStatus: updatedAssessments })
      })
    })
  }

  render() {
    const { subject, assessmentsWithStatus, isLoading } = this.state
    const doAssessmentsExist = assessmentsWithStatus.length > 0
    return (
      <div className='subject-view_container'>
        <PhysicianHeader />
        {isLoading ? (
          <div className='subject-view_loader-container'>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <p>Loading subject data...</p>
          </div>
        ) : (
          !isLoading &&
          subject && (
            <div className='subject-view_content-container'>
              <div className='subject-view_button-container'>
                <button
                  className='subject-view_back-button'
                  onClick={() => this.props.history.push('/physician')}
                >
                  Back to Subjects
                </button>
                <button
                  onClick={() => this.fetchAndSetSubjectsAssessments()}
                  className='physician_refresh-button'
                >
                  <i className='fas fa-sync' />
                </button>
              </div>
              <div className='subject-view_patient-info-container'>
                <div className='subject-view_patient-info'>
                  <p>{this.state.subject.pid.name.first}</p>
                  <p>{this.state.subject.pid.name.last}</p>
                  <p>{this.state.subject.pid.dob}</p>
                </div>
                <div>
                  <button
                    className='subject-view_add-assessment-button'
                    onClick={() => this.openAssessmentModal()}
                  >
                    Add Assessment
                  </button>
                </div>
              </div>
              <h2 className='subject-view_table-title'>Assigned Assessments</h2>
              <div className='subject-view_table-wrapper'>
                {doAssessmentsExist ? (
                  <BootstrapTable
                    rowClasses='subject-view_data-table-row'
                    keyField='document'
                    data={assessmentsWithStatus}
                    selectRow={this.rowSettings}
                    columns={this.columns}
                  />
                ) : (
                  <p>Patient has no Assessments</p>
                )}
              </div>
            </div>
          )
        )}
        <AssessmentModal
          isModalOpen={this.state.isModalOpen}
          handleClose={this.closeAssessmentModal}
          hsgAssessments={this.state.hsgAssessments}
          subject={subject}
        />
      </div>
    )
  }
}

SubjectView.propTypes = {
  selectedSubject: PropTypes.object,
  fetchSubjectAssessments: PropTypes.func
}

const mapDispatchToProps = {}

export default connect(
  null,
  mapDispatchToProps
)(SubjectView)
