import jwtDecode from 'jwt-decode'

export const checkForSubject = () => {
  const jwtToken = localStorage.getItem('jwtToken')
  if (jwtToken) {
    const parsedJwt = jwtDecode(jwtToken)
    if (parsedJwt.client_id === process.env.REACT_APP_PATIENT_APP_CLIENT) {
      return true
    } else {
      return false
    }
  }
}