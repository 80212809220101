import React, { Component } from 'react'
import Cover from './Cover'
import Questionnaire from './Questionnaire'

class Assessment extends Component {
  constructor(props) {
    super()
    this.state = {
      assessments: props.assessments,
      isGoingThroughAssessment: false,
      currentAssessmentIndex: 0,
      currentAssessment: null
    }
  }

  startAssessment = ({ assessment }) => {
    const { assessments, currentAssessmentIndex } = this.state
    this.setState({
      isGoingThroughAssessment: true,
      currentAssessment: assessment
    })
  }

  goToNextAssessment = () => {
    const { assessments, currentAssessmentIndex } = this.state
    this.setState({
      currentAssessmentIndex: this.state.currentAssessmentIndex + 1,
      currentAssessment: assessments[currentAssessmentIndex]
    })
  }

  stopAssessment = () => {
    this.setState({
      isGoingThroughAssessment: false,
      currentAssessmentIndex: 0,
      currentAssessment: null
    })
  }

  componentDidMount() {}

  render() {
    const { isGoingThroughAssessment, currentAssessment } = this.state
    return (
      <>
        {!isGoingThroughAssessment && (
          <Cover
            profile={this.props.profile}
            startAssessment={this.startAssessment}
            assessments={this.state.assessments}
          />
        )}
        {isGoingThroughAssessment && (
          <Questionnaire assessment={currentAssessment} />
        )}
      </>
    )
  }
}

export default Assessment
