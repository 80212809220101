import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchHsgSubjects } from 'api/subjects'
import { fetchSubject } from 'api/subjects'
import { fetchSubjectAssessments } from 'api/assessments'
import BootstrapTable from 'react-bootstrap-table-next'
import SubjectView from './SubjectView'
import PhysicianHeader from './PhysicianHeader'
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit'
import paginationFactory from 'react-bootstrap-table2-paginator'
import Loader from 'react-loader-spinner'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
const { SearchBar } = Search

class Physician extends Component {
  constructor() {
    super()
    this.state = {
      subjects: [],
      selectedSubject: null,
      isLoadingSubject: false
    }
    this.columns = [
      {
        dataField: 'firstname',
        text: 'First Name'
      },
      {
        dataField: 'lastname',
        text: 'Last Name'
      }
    ]
    this.rowSettings = {
      mode: 'checkbox',
      clickToSelect: true,
      hideSelectColumn: true,
      onSelect: subject => {
        // this.setState({ isLoadingSubject: true })
        this.props.history.push('/physician/' + subject.id)
      }
    }
    this.paginationSettings = paginationFactory({
      sizePerPage: 10,
    })
  }
  handleSubjectSelect = ({ selectedSubject }) => {
    this.setState({ selectedSubject })
  }

  componentWillMount() {
    this.fetchAndSetSubjects()
  }

  fetchAndSetSubjects = () => {
    this.setState({ isFetchingSubjects: true })
    fetchHsgSubjects().then(response => {
      const subjects = response.data
      const formattedSubjects = subjects.map(subject => {
        return {
          firstname: subject.pid.name.first,
          lastname: subject.pid.name.last,
          id: subject.id
        }
      })
      this.setState({ subjects: formattedSubjects, isFetchingSubjects: false })
    })
  }

  render() {
    const { selectedSubject, isLoadingSubject, isFetchingSubjects } = this.state
    return isLoadingSubject ? (
      <div className='physician_loader-container'>
        <Loader type='Rings' color='#8DC440' height={80} width={80} />
        <p>Fetching data...</p>
      </div>
    ) : (
      <div className='physician_wrapper'>
        <PhysicianHeader />
        {isFetchingSubjects ? (
          <div className='physician_loader-container'>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
            <p>Fetching data...</p>
          </div>
        ) : (
          <ToolkitProvider
            data={this.state.subjects}
            columns={this.columns}
            keyField='id'
            bootstrap4
            search
            remote
          >
            {props => (
              <div>
                <p className='physician_table-instructions'>
                  Please select a patient to assign new assessments and view
                  completed assessments.
                </p>
                <div className='physician_search-container'>
                  <button
                    onClick={() => this.fetchAndSetSubjects()}
                    className='physician_refresh-button'
                  >
                    <i className='fas fa-sync' />
                  </button>
                  <SearchBar {...props.searchProps} />
                </div>
                <BootstrapTable
                  rowClasses='physician_data-table-row'
                  keyField='id'
                  selectRow={this.rowSettings}
                  pagination={this.paginationSettings}
                  noDataIndication='No subjects to view'
                  {...props.baseProps}
                />
              </div>
            )}
          </ToolkitProvider>
        )}
      </div>
    )
  }
}

const mapDispatchToProps = {}

export default connect(
  null,
  mapDispatchToProps
)(Physician)
