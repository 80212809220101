const Types = {
  SET_SUBJECT_PROFILE: 'SET_SUBJECT_PROFILE',
  SET_SUBJECT: 'SET_SUBJECT'
};

export const setSubjectProfile = ({ profile }) => ({
  type: Types.SET_SUBJECT_PROFILE,
  payload: profile
})

export const setSubject = ({ subject }) => ({
  type: Types.SET_SUBJECT,
  payload: subject
})

export default {
  setSubjectProfile,
  Types
};

