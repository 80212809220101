import { makeLamdaRequest } from './lambdaHelper'
import axios from 'axios'

const hsgGuid = process.env.REACT_APP_PATIENT_APP_CLIENT

export const fetchHsgSubjects = () => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `subjects/subgroup/${hsgGuid}`
  })
}

export const fetchSubject = ({ subject }) => {
  const pid = subject.id
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `subjects/${pid}/${hsgGuid}`
  })
}

export const addSubject = ({ subject, trace = {} }) => {
  return axios({
    headers: {
      'x-api-key': process.env.REACT_APP_LAMBDA_AWS_DEV_SUBJECTS_API_KEY,
      'Content-Type': 'application/json',
    },
    method: 'POST',
    data: {
      ...subject,
      trace
    },
    url: 'https://4ckx6co6f9.execute-api.us-east-1.amazonaws.com/dev/subjects'
  })
}

export const fetchSubjectById = ({ pid }) => {
  return makeLamdaRequest({
    method: 'GET',
    endpoint: `subjects/${pid}/${hsgGuid}`
  })
}