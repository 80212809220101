import ACTIONS from '../actions/subjectActions';
import _ from 'lodash';

const defaultState = {}

const adminReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ACTIONS.Types.SET_SUBJECT_PROFILE: {
      const profile = action.payload
      const newState = _.cloneDeep(state);
      newState.profile = profile
      return newState;
    }
    default:
      return state;
  }
};

export default adminReducer;