import React from 'react'
import { logout } from 'authentication/authentication'
const hdInsightLogo = require('assets/images/MyHDStory.png')
const ntLogo = require('assets/images/nt-logo.png')


const getSourceOrigin = ({ source }) => {
  if (source) {
    const sourceToLowercase = source.toLowerCase()
    if (sourceToLowercase === 'foxinsight') {
      return 'foxinsight'
    } else if (sourceToLowercase === 'hdinsight') {
      return 'hdinsight'
    } else {
      return ''
    }
  }
}

const renderDynamicLogo = ({ sourceOrigin }) => {
  let logo = hdInsightLogo
  return (
    <div
      className={`homepage-header_logo-container homepage-header_logo-container--${sourceOrigin ||
        'nt'}`}
    >
      <img className='homepage-header_logo' src={logo} />
    </div>
  )
}

const Header = ({ source, history }) => {
  const sourceOrigin = getSourceOrigin({ source })
  return (
    <div className='homepage-header-container'>
      <div className='homepage-header_content-container'>
        {renderDynamicLogo({ sourceOrigin })}
      </div>
      <div className='homepage-header_nav-menu'>
        <p className='homepage-header_nav-link'>Questionnaire</p>
        <p className='homepage-header_nav-link'>Reports</p>
        <p onClick={() => history.push('/profile')} className='homepage-header_nav-link'>Profile</p>
      </div>
      <div className='homepage-header_nav-user-container'>
        <i className='homepage-header_nav-user fas fa-user' />
        <p onClick={() => logout()} className='homepage-header_logout'>Logout</p>
      </div>
    </div>
  )
}

export default Header
