import React, { Component } from 'react'
import Header from './Header'
import Sidebar from './Sidebar'
import MainContent from './MainContent'
import Assessment from '../assessment/Assessment'
import Loader from 'react-loader-spinner'
import { checkForSubject } from 'helpers/user'

import { connect } from 'react-redux'
import { fetchSubjectAssessments } from 'api/assessments'
import { getQuestionsFromAssessments } from 'helpers/assessment'

class Homepage extends Component {
  state = {
    questions: [],
    isLoading: false,
    assessments: []
  }
  componentWillMount() {
    const isUserSubject = checkForSubject()
    const { profile, subject } = this.props
    this.setState({
      isLoading: true,
      isSubject: isUserSubject
    })
    if (!profile || !profile.isSubject) {
      this.props.history.push('/physician')
    } else {
      fetchSubjectAssessments({
        pid: profile.subjectId
      }).then(response => {
        const assessments = response.data
        const formattedAsses = getQuestionsFromAssessments({ assessments })
        const incompleteAssessments = formattedAsses.filter(
          assessment => !assessment.isComplete
        )
        this.setState({
          allAssessments: formattedAsses,
          incompleteAssessments,
          isLoading: false
        })
      })
    }
  }
  renderAssessments = ({ assessments }) => {
    return assessments.map((ass, index) => {
      return (
        <div key={index}>
          <p>{ass.assessmentTitle}</p>
          {ass.questions.map((question, index) => {
            return (
              <div key={index}>
                <p>{question.questionTitle}</p>
              </div>
            )
          })}
        </div>
      )
    })
  }
  render() {
    const { isLoading, incompleteAssessments } = this.state
    return (
      <div className='homepage-container'>
        <Header history={this.props.history}/>
        {isLoading ? (
          <div className='homepage-loader_container'>
            <Loader type='Rings' color='#8DC440' height={80} width={80} />
          </div>
        ) : (
          <div className='homepage-body-container'>
            <Assessment
              profile={this.props.profile}
              assessments={incompleteAssessments}
            />
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = state => ({
  profile: state.subject.profile,
  subject: state.subject.subject
})

const mapDispatchToProps = {}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Homepage)
