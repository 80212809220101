import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { fillForm } from 'helpers/assessment'
import _ from 'lodash'

class Questionnaire extends Component {
  constructor(props) {
    console.log('PROPS', props)
    super()
    this.state = {
      questions: props.assessment.questions,
      assessment: props.assessment,
      currentQuestionIndex: 0,
      hasSeenInstructions: false,
      shortAnswerValue: '',
      radioInputValue: null,
      hasFinishedAssessment: false,
      isAnswerBlank: false,
      dictUid: null
    }
    this.throttleSave = _.debounce(this.handleFillForm, 400)
  }

  goToNextQuestion = () => () => {
    const {
      currentQuestionIndex,
      questions,
      shortAnswerValue,
      radioInputValue
    } = this.state
    const hasNoAnswer = !shortAnswerValue && !radioInputValue
    const isLastQuestion = currentQuestionIndex === questions.length - 1
    const currenQuestionUid = questions[currentQuestionIndex].dictUid
    if (hasNoAnswer) {
      this.setState(
        {
          dictUid: currenQuestionUid,
          currentQuestionIndex: currentQuestionIndex + 1
        },
        () => this.handleFillForm()
      )
    } else if (isLastQuestion) {
      this.setState({ hasFinishedAssessment: true })
    } else {
      this.setState({
        currentQuestionIndex: currentQuestionIndex + 1,
        shortAnswerValue: '',
        radioInputValue: null
      })
    }
  }

  handleSeeingInstructions = () => () => {
    this.setState({ hasSeenInstructions: true })
  }

  renderInstructions = ({ instructions }) => {
    return (
      <div className='assessment-instructions_container'>
        <div className='assessment-instructions_instruction-wrapper'>
          {instructions.map((instruction, i) => {
            return (
              <p key={i}>
                {i + 1}) {instruction}
              </p>
            )
          })}
        </div>
        <button
          className='assessment-instructions_submit'
          onClick={this.handleSeeingInstructions()}
        >
          Got it
        </button>
      </div>
    )
  }

  handleChange = ({ e, dictUid, isRadioInput }) => {
    const { isAnswerBlank } = this.state
    const answer = e.target.value
    if (isRadioInput) {
      this.setState({
        radioInputValue: answer,
        dictUid
      })
    } else {
      if (isAnswerBlank) {
        this.setState({ isAnswerBlank: false })
      }
      this.setState({
        shortAnswerValue: answer,
        dictUid
      })
    }
    this.throttleSave()
  }

  handleFillForm = () => {
    const {
      shortAnswerValue,
      dictUid,
      assessment,
      radioInputValue,
      currentQuestionIndex,
      questions,
    } = this.state
    const isLastQuestion = currentQuestionIndex === questions.length - 1
    const answer = shortAnswerValue || radioInputValue || 'n/a'
    fillForm({ assessment, dictUid, answer, isLastQuestion })
  }

  renderAnswer = ({ answerType, optionsForScale, dictUid }) => {
    const { radioInputValue } = this.state
    if (answerType === 'shortAnswer') {
      return (
        <textarea
          cols='60'
          rows='8'
          value={this.state.shortAnswerValue}
          className='assessment-question_input-text-area'
          onChange={e => this.handleChange({ e, dictUid })}
        />
      )
    } else if (answerType === 'scale') {
      return (
        <div className='assessment-question_radio-container'>
          {optionsForScale.map(option => {
            const isChecked = option.value.toString() === radioInputValue
            return (
              <div key={option.value}>
                <input
                  type='radio'
                  className='assessment-question_radio-input'
                  id={option.value}
                  name={dictUid}
                  checked={isChecked}
                  onChange={e =>
                    this.handleChange({ e, dictUid, isRadioInput: true })
                  }
                  value={option.value}
                />
                <label
                  className='assessment-question_radio-label'
                  htmlFor={option.value}
                >
                  {option.label}
                </label>
              </div>
            )
          })}
        </div>
      )
    }
  }

  componentDidMount() {}

  goToHomePage = () => {
    const { history } = this.props
    history.push('/')
  }

  render() {
    const {
      questions,
      currentQuestionIndex,
      assessment,
      hasSeenInstructions,
      hasFinishedAssessment
    } = this.state
    const directions = assessment.instructions.directions
    const assessmentTitle = assessment.assessmentTitle
    const currentQuestion = questions[currentQuestionIndex]
    const shouldShowQuestions = hasSeenInstructions === true || !directions
    const {
      answerType,
      questionTitle,
      dictUid,
      optionsForScale
    } = currentQuestion
    return (
      <>
        {directions &&
          !hasSeenInstructions &&
          this.renderInstructions({ instructions: directions })}
        {shouldShowQuestions && !hasFinishedAssessment && (
          <div className='assessment-question_container'>
            <div className='assessment-question_next-button-container'>
              <button
                className='assessment-question_next-button'
                onClick={this.goToNextQuestion()}
              >
                Next
              </button>
            </div>
            <div className='assessment-question_content-container'>
              <p className='assessment-question_title'>{questionTitle}</p>
              {this.renderAnswer({ answerType, optionsForScale, dictUid })}
            </div>
          </div>
        )}
        {hasFinishedAssessment && (
          <div className='assessment-question_container'>
            <p>You completed the {assessmentTitle} assessment!</p>
            <button
              className='assessment-question_back-to-assessment-button'
              onClick={() => window.location.reload()}
            >
              Back To Assessments
            </button>
          </div>
        )}
      </>
    )
  }
}

export default withRouter(Questionnaire)
