import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Loader from 'react-loader-spinner'
import { withRouter } from 'react-router-dom'

class AssessmentCover extends Component {
  constructor(props) {
    super()
    this.state = {
      assessments: props.assessments
    }
  }

  handleAssessmentStart = ({ assessment }) => () => {
    console.log('ASSERRR', assessment)
    const { assessments } = this.state
    const { startAssessment } = this.props
    startAssessment({ assessment })
  }

  componentDidMount() {}

  render() {
    const { assessments } = this.state
    console.log('ASSESSMENTS', assessments)
    const { profile } = this.props
    const assessmentCount = assessments.length
    const message = `You have ${assessmentCount} new Assessment${
      assessmentCount > 1 ? 's' : ''
    } to complete`
    const doAssessmentsExists = assessments && assessments.length > 0
    return doAssessmentsExists ? (
      <div>
        {assessments.map(assessment => (
          <div
            key={assessment.assessmentTitle}
            className='assessment-cover_container'
          >
            <>
              <h2>{assessment.assessmentTitle}</h2>
              <i className='assessment-cover_icon far fa-comment-dots' />
              <p>
                Please click the button below to complete the{' '}
                {assessment.assessmentTitle} assessment
              </p>
              <button
                className='assessment-cover_submit'
                onClick={this.handleAssessmentStart({ assessment })}
              >
                start
              </button>
            </>
          </div>
        ))}
      </div>
    ) : (
      <div>
        <div className='assessment-cover_container'>
          <div className='assessment-cover_no-content-wrapper'>
            <div className='assessment-cover_no-content-container'>
              <div className='assessment-cover_no-content'>
                <p className='assessment-cover_no-content-title'>
                  You have no new Assessments.
                </p>
                <p className='assessment-cover_no-content-subtitle'>
                  Please check back at a later date.
                </p>
              </div>
            </div>
            <i className='assessment-cover_no-content-icon fas fa-user-md' />
          </div>
        </div>
      </div>
    )
  }
}

AssessmentCover.propTypes = {
  history: PropTypes.object,
  assessments: PropTypes.array,
  startAssessment: PropTypes.func
}

export default withRouter(AssessmentCover)
