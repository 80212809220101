import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Modal from '@material-ui/core/Modal'

const hippaLogo = require('../assets/images/hipaa_white.png')

const getModalStyle = () => {
  const top = 40
  const left = 50
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${50}%, -${50}%)`
  }
}

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: 400,
    backgroundColor: '#367fa9',
    boxShadow: theme.shadows[5],
    padding: '1.5rem 1.5rem 2rem 1.5rem',
    outline: 'none',
    borderRadius: '5px',
    textAlign: 'center'
  }
}))

function TermsOfServiceModal({ isOpen, handleClose }) {
  const [modalStyle] = React.useState(getModalStyle)
  const classes = useStyles()

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={handleClose}
      >
        <div style={modalStyle} className={classes.paper}>
          <img alt='hippa-logo' src={hippaLogo} className='tos_hippa-logo' />
          <div className='tos_text-container'>
            <p className='tos_text'>
            This is an extension of Neurotargeting network and security rules are the same.
            </p>
            <p className='tos_text'>
            By entering the system you understand that it may contain protected PHI information
            and you agree to respect HIPAA and Neurotargeting privacy and security rules.
            Violation to HIPAA regulation is a federal crime and may result in penalties that might not be exclusively financial.
            </p>
          </div>
          <TermsOfServiceModal />
        </div>
      </Modal>
    </div>
  )
}

export default TermsOfServiceModal
