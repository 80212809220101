import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import { blue } from '@material-ui/core/colors'
import Loader from 'react-loader-spinner'
import { createAssessment } from 'api/assessments'

const addAssessmentToPatient = ({
  subject,
  assessment,
  handleClose,
  setAssessmentCreationStatus,
  setSelectedAssessment
}) => {
  setAssessmentCreationStatus(true)
  setSelectedAssessment(assessment)
  createAssessment({ assessment, pid: subject.id }).then(response => {
    const newAssessment = response.data
    handleClose()
    setTimeout(() => setAssessmentCreationStatus(false), 1000)
  })
}

export default function AssessmentModal(props) {
  const { isModalOpen, handleClose, hsgAssessments, subject } = props
  const [isCreatingAssessment, setAssessmentCreationStatus] = useState(false)
  const [selectedAssessment, setSelectedAssessment] = useState(null)
  return (
    <div>
      <Dialog
        onClose={handleClose}
        aria-labelledby='simple-dialog-title'
        open={isModalOpen}
      >
        <div className='assessment-modal_wrapper'>
          {isCreatingAssessment ? (
            <div className='assessment-loader_container'>
              <Loader type='Rings' color='#8DC440' height={80} width={80} />
              <p>Assiging {selectedAssessment.label}...</p>
            </div>
          ) : subject && (
            <>
              <h2 className='assessment-modal_title'>
                Assign one of the below to {subject.pid.name.first}{' '}
                {subject.pid.name.last}:
              </h2>
              {hsgAssessments &&
                hsgAssessments.map(assessment => {
                  return (
                    <div
                      className='assessment-modal_option'
                      onClick={() =>
                        addAssessmentToPatient({
                          subject,
                          assessment,
                          handleClose,
                          setAssessmentCreationStatus,
                          setSelectedAssessment
                        })
                      }
                      key={assessment.id}
                    >
                      {assessment.label}
                    </div>
                  )
                })}
            </>
          )}
        </div>
      </Dialog>
    </div>
  )
}

AssessmentModal.propTypes = {
  isModalOpen: PropTypes.bool,
  handleClose: PropTypes.func,
  hsgAssessments: PropTypes.array,
  subject: PropTypes.object
}
