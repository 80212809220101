import axios from 'axios'

const getApi = ({ endpoint }) => {
  let api
  const isSubjects = endpoint.includes('subjects') && !endpoint.includes('access-view')
  const isProfile = endpoint.includes('profile')
  const isDocuemnt = endpoint.includes('documents')
  const isNeptune = endpoint.includes('neptune')
  if (isProfile) {
    api = 'profile'
  } else if (isSubjects) {
    api = 'subjects'
  } else if (isDocuemnt) {
    api = 'documents'
  } else if (isNeptune) {
    api = 'neptune'
  } else {
    api = 'localhost'
  }
  return api
}

const getEndpoint = ({ endpoint }) => {
  let finalEndpoint
  const api = getApi({ endpoint })
  switch (api) {
  case 'profile':
    finalEndpoint = 'https://r1iorpnuo6.execute-api.us-east-1.amazonaws.com/dev'
    break
  case 'subjects':
    finalEndpoint = 'https://4ckx6co6f9.execute-api.us-east-1.amazonaws.com/dev'
    break
  case 'documents':
    finalEndpoint = 'https://t0m9kbsx0l.execute-api.us-east-1.amazonaws.com/dev'
    break
  case 'neptune':
    finalEndpoint = 'https://uohvv6oa90.execute-api.us-east-1.amazonaws.com/dev'
    break
  default:
    finalEndpoint = 'http://localhost:3000'
    break
  }
  return finalEndpoint
}

const getApiKey = ({ endpoint }) => {
  let apiKey
  const api = getApi({ endpoint })
  switch (api) {
  case 'profile':
    apiKey = process.env.REACT_APP_LAMBDA_AWS_DEV_PROFILE_API_KEY
    break
  case 'subjects':
    apiKey = process.env.REACT_APP_LAMBDA_AWS_DEV_SUBJECTS_API_KEY
    break
  case 'documents':
    apiKey = process.env.REACT_APP_LAMBDA_AWS_DEV_ASSESSMENTS_API_KEY
    break
  case 'neptune':
    apiKey = process.env.REACT_APP_LAMBDA_AWS_DEV_NEPTUNE
    break
  default:
    apiKey = process.env.REACT_APP_LAMBDA_AWS_DEV_API_KEY
    break
  }
  return apiKey
}

export const makeLamdaRequest = ({
  method = 'GET',
  endpoint,
  parameter,
  body = {},
  headers
}) => {
  const apiKey = getApiKey({ endpoint })
  const finalPath = getEndpoint({ endpoint })
  const fullUrl = `${finalPath}/${endpoint}${parameter ? `/${parameter}` : ''}`
  return axios({
    headers: {
      'x-api-key': apiKey,
      'Content-Type': 'application/json',
      ...headers
    },
    method,
    data: JSON.stringify(body),
    url: fullUrl
  })
}
