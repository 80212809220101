import React from 'react';
import Homepage from './subject/Homepage'
import MainMenu from './MainMenu'
import Login from './Login'
import Signup from './Signup'
import Physician from './Physician/Physician'
import AssessmentView from './Physician/AssessmentView'
import Profile from './profile/Profile'
import SignupConfirmation from './SignupConfirmation'
import PrivateRoute from './PrivateRoute'
import SubjectView from './Physician/SubjectView'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux'
import configureStore from 'reducer/store'

const { store, persistor } = configureStore(window.REDUX_INITIAL_DATA)

function App() {
  return (<div>
    <ReduxProvider store={store}>
      <Router>
        <Route exact path='/signup' component={Signup} />
        <Route path='/signup/confirmation/:username?' component={SignupConfirmation} />
        <Route exact path='/physician/login' component={Login} />
        <Route exact path='/login' component={Login} />
        <PrivateRoute exact path='/physician' component={Physician} />
        <PrivateRoute exact path='/physician/:subjectId' component={SubjectView} />
        <PrivateRoute exact path='/physician/:assessmentId/:document' component={AssessmentView} />
        <PrivateRoute isSubject exact path='/' component={Homepage} />
        <PrivateRoute isSubject exact path='/profile' component={Profile} />
      </Router>
    </ReduxProvider>
  </div>
  )
}

export default App;